import React from "react";
import styled from "styled-components";

import { Section, Container } from "../global";

const Industries = () => (
  <Section id="industries">
    <StyledContainer>
      <Subtitle>Applications</Subtitle>
      <SectionTitle>Ranger Improves:</SectionTitle>
      <IndustriesGrid>
        <IndustryItem>
          <IndustryTitle>C-Suite Operations</IndustryTitle>
          <IndustryText>
            Allow your team to build faster, automated reports with Ranger so
            you can have a better view into the financial and operational
            details of your business.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Financial Planning and Analysis</IndustryTitle>
          <IndustryText>
            Save your FP&A team's time by automating recurring reporting and
            eliminate errors with built-in version control.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Portfolio Management</IndustryTitle>
          <IndustryText>
            Make decisions faster by centralizing and organizing investment
            data.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Investment Monitoring</IndustryTitle>
          <IndustryText>
            Save your investment team huge time every quarter and limit errors
            by eliminating manual entry into an investment monitoring database.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Investor Relations</IndustryTitle>
          <IndustryText>
            Spend your time working with clients instead of building quarterly
            client reports by using Ranger to automate data collection across
            your company.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Sales Management</IndustryTitle>
          <IndustryText>
            Aggregate forecasts within and across sales teams and product lines
            easily and painlessly.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Franchise Reporting</IndustryTitle>
          <IndustryText>
            Roll up data from any number of subsidiaries in one simple process
            that cuts down on tedious aggregation and eliminates errors.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Inventory Management</IndustryTitle>
          <IndustryText>
            Collect and share inventory tracking data easier so you can make
            better purchasing decisions.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Management Reporting</IndustryTitle>
          <IndustryText>
            Get to decisions faster and save on overhead costs by automating
            data collection and reporting within your organization.
          </IndustryText>
        </IndustryItem>
        <IndustryItem>
          <IndustryTitle>Supply Chain Operations</IndustryTitle>
          <IndustryText>
            Tired of sharing large data files over FTP connections? Collect data
            from your vendors faster and directly into Excel.
          </IndustryText>
        </IndustryItem>
      </IndustriesGrid>
    </StyledContainer>
  </Section>
);

export default Industries;

const StyledContainer = styled(Container)``;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const IndustriesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const IndustryItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IndustryTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
`;

const IndustryText = styled.p`
  text-align: center;
`;

import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import Features from "../components/sections/features";
import Footer from "../components/sections/footer";
import CtaFooter from "../components/sections/cta-footer";
import ProductHeader from "../components/sections/product-header";
import Industries from "../components/sections/industries";

const SolutionsPage = () => (
  <Layout>
    <SEO
      title="Ranger Solutions"
      description="The Ranger Excel Add-in is the solution to data processes in family offices, FP&A, management reporting and more. Ranger is an Excel Add-in and plugin for Excel that provides a no-code SaaS solution for protecting, centralizing and storing Excel data by connecting it to the cloud."
    />
    <Navigation />
    <ProductHeader />
    <Industries />
    <CtaFooter />
    <Footer />
  </Layout>
);

export default SolutionsPage;
